import React from 'react';
import { Link } from 'react-router-dom';
import { FaQuestionCircle, FaBell } from 'react-icons/fa'; // Import icons from react-icons

const HelpSupport = () => {
  return (
    <div className="w-full flex justify-center items-start min-h-screen bg-gray-100 py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* FAQ Tile */}
        <Link 
          to="/help-support/faqs" 
          className="flex flex-col justify-start items-start bg-white rounded-lg shadow-md hover:shadow-lg transition p-8 w-full h-64"
        >
          {/* Icon in green color */}
          <FaQuestionCircle className="text-green-600 text-4xl mb-4" />
          {/* Title and Description */}
          <h2 className="text-2xl font-bold mb-2">Frequently Asked Questions</h2>
          <p className="text-gray-600">Find answers to common questions.</p>
        </Link>

        {/* Alerts Tile */}
        <Link 
          to="/help-support/alerts" 
          className="flex flex-col justify-start items-start bg-white rounded-lg shadow-md hover:shadow-lg transition p-8 w-full h-64"
        >
          {/* Icon in green color */}
          <FaBell className="text-green-600 text-4xl mb-4" />
          {/* Title and Description */}
          <h2 className="text-2xl font-bold mb-2">Alerts</h2>
          <p className="text-gray-600">See Alerts and guidance on managing them.</p>
        </Link>
      </div>
    </div>
  );
};

export default HelpSupport;
