import React from 'react';
import { PortableText } from '@portabletext/react';

const customPortableTextComponents = {
  types: {
    // Custom rendering for different block types like images
    image: ({ value }) => {
      return <img src={value.asset.url} alt={value.alt || 'Image'} />;
    },
  },
  block: {
    // Custom serializers for different block types
    h1: ({ children }) => <h1 className="text-3xl font-bold my-4">{children}</h1>,
    h2: ({ children }) => <h2 className="text-2xl font-semibold my-3">{children}</h2>,
    h3: ({ children }) => <h3 className="text-xl font-medium my-2">{children}</h3>,
    normal: ({ children }) => <p className="text-md my-2">{children}</p>,
  },
  list: {
    // Handle bullet points (unordered list)
    bullet: ({ children }) => <ul className="list-disc ml-5 my-2">{children}</ul>,

    // Handle numbered lists (ordered list)
    number: ({ children }) => <ol className="list-decimal ml-5 my-2">{children}</ol>,
  },
  marks: {
    // Custom rendering for links
    link: ({ children, value }) => {
      const target = value?.href?.startsWith('http') ? '_blank' : undefined;
      return (
        <a
          href={value.href}
          target={target}
          rel={target === '_blank' ? 'noopener noreferrer' : undefined}
          className="underline text-blue-600 hover:text-blue-800"
        >
          {children}
        </a>
      );
    },
  },
};

const PortableTextComponent = ({ value }) => {
  return (
    <PortableText
      value={value}
      components={customPortableTextComponents}
    />
  );
};

export default PortableTextComponent;
