import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import sanityClient from '../sanityClient';
import { urlFor } from '../imageBuilder'; 
import PortableTextComponent from '../components/PortableTextComponent';
import Modal from '../components/Modal'; // Import the modal

const AlertsArticle = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);

  // State for handling modal visibility and selected image
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    sanityClient.fetch(
      `*[_type == "alert" && slug.current == $slug][0]{
         _createdAt,
         name,
         author->{name},
         banner,
         synopsis,
         categoryType,
         articleType,
         content,
         sidebar,
         images
      }`,
      { slug }
    ).then((data) => setArticle(data))
      .catch(console.error);
  }, [slug]);

  // Function to open the modal with the selected image
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  if (!article) return <div>Loading...</div>;

  return (
    <div className="w-full md:w-4/5 mx-auto py-8 px-4 md:px-0"> {/* Use full width on small screens */} 
      {/* Banner Image */}
      <div className="mb-8">
        <img
          src={urlFor(article.banner).width(1200).url()}
          alt={`Banner for ${article.name}`}
          className="w-full h-auto object-contain rounded-lg"  // Ensure aspect ratio is maintained
        />
      </div>

      <div id="article-content">
        {/* Article Title */}
        <h2 className="text-2xl md:text-3xl font-bold mt-6">{article.name}</h2>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Left Column (Article Content) */}
          <div className="md:col-span-3">
            {/* Author, Article Type, Category, Date */}
            <div className="flex flex-wrap items-center space-x-2 md:space-x-4 mt-2">
              <div className="bg-orange-100 text-orange-900 text-xs font-bold px-3 py-1 rounded-full mb-2 md:mb-0 whitespace-nowrap">
                {article.categoryType} {/* Prevents pill from stretching and keeps it compact */}
              </div>
              <p className="text-sm text-gray-700 whitespace-nowrap">Author: {article.author.name}</p>
              <p className="text-sm text-gray-600 whitespace-nowrap">{article.articleType}</p>
              <p className="text-sm text-gray-600 whitespace-nowrap">
                {new Date(article._createdAt).toLocaleDateString('en-GB')}
              </p>
            </div>

            {/* Synopsis */}
            <p className="text-md mt-4 italic">{article.synopsis}</p>

            {/* Content */}
            <div className="mt-6">
              <PortableTextComponent value={article.content} />
            </div>

            {/* Sidebar with lighter olive green, white text, and rounded corners */}
            <div
              className="p-4 mt-8 text-white rounded-lg"
              style={{ backgroundColor: '#6B8E23' }}
            >
              <PortableTextComponent value={article.sidebar} />
            </div>
          </div>

          {/* Right Column (Images and Data Sources) */}
          <div className="md:col-span-1 md:mt-12 flex flex-col space-y-4">
            {article.images?.map((img, idx) => (
              <img
                key={idx}
                src={urlFor(img).url()}
                alt={`Image related to ${article.name} (${idx + 1})`}
                className="cursor-pointer w-full h-auto object-contain rounded-lg"
                onClick={() => openModal(urlFor(img).url())}
              />
            ))}

          </div>
        </div>

        {/* Modal for displaying full-size image */}
        {isModalOpen && (
          <Modal imageUrl={selectedImage} onClose={closeModal} />
        )}
      </div>
    </div>
  );
};

export default AlertsArticle;
