import React from 'react';
import { Link } from 'react-router-dom';
import { FaChartLine, FaLifeRing } from 'react-icons/fa'; // Import icons for Market Insights and Help & Support

const Home = () => {
  return (
    <div className="w-full flex justify-center items-start min-h-screen bg-gray-100 py-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full max-w-2xl">
        {/* Market Insights Tile */}
        <Link 
          to="/market-insights" 
          className="flex flex-col justify-start items-start bg-white rounded-lg shadow-md hover:shadow-lg transition p-6"
          style={{ aspectRatio: '1/1' }}  // Ensures the tile is square
        >
          {/* Icon for Market Insights */}
          <FaChartLine className="text-green-600 text-3xl mb-3" />
          {/* Title and Description */}
          <h2 className="text-xl font-bold mb-2">Market Insights</h2>
          <p className="text-gray-600 text-sm">Get the latest insights on market trends, data, and analysis.</p>
        </Link>

        {/* Help & Support Tile */}
        <Link 
          to="/help-support" 
          className="flex flex-col justify-start items-start bg-white rounded-lg shadow-md hover:shadow-lg transition p-6"
          style={{ aspectRatio: '1/1' }}  // Ensures the tile is square
        >
          {/* Icon for Help & Support */}
          <FaLifeRing className="text-green-600 text-3xl mb-3" />
          {/* Title and Description */}
          <h2 className="text-xl font-bold mb-2">Help & Support</h2>
          <p className="text-gray-600 text-sm">Access FAQs and Alerts.</p>
        </Link>
      </div>
    </div>
  );
};

export default Home;
